(function()
{

	$('.confirm-delete').on('click', function(e)
	{
		if(!confirm('Weet je zeker dat je dit item wilt verwijderen?'))
		{
			return false;
		}
	});

	$('.confirm-cancel').on('click', function(e)
	{
		if(!confirm('Weet je zeker dat je wilt annuleren?'))
		{
			return false;
		}
	});






	$('.tags').tagsInput({
		defaultText: 'Label toevoegen...'
	});



	if($('.image-editor').length)
	{

		$('.image-editor').cropit({
			imageBackground: true,
			imageBackgroundBorderWidth: 15,
			exportZoom: 2,
			smallImage: 'stretch',

			onFileChange: function()
			{
				console.log('file changed');
				$(this).siblings('.cropit-cropped-input').val('');
			},

			onImageError: function(error)
			{

				console.log('has error');
				setErrorMessage(error);
			},

			onImageLoaded: function()
			{
				console.log('image loaded');
				clearErrorMessage();
			},

			onZoomChange: function()
			{
				console.log('zoom changed');
			},

			onOffsetChange: function()
			{
				console.log('offset changed');
			}


		});

		if($('.image-editor').data('imgpath') && $('.image-editor').data('imgpath') !== '')
		{
			$('.image-editor').cropit('imageSrc', $('.image-editor').data('imgpath'));
		}


		$('.select-image-btn').on('click', function(e)
		{
			$(this).siblings('.cropit-image-input').trigger('click');
		});

		$('.recrop-btn').on('click', function()
		{
			$('.image-editor').cropit('imageSrc', $('.image-editor').data('imgpath').replace('/preview', ''));
			$(this).remove();
		});


		$('.image-editor').on('mouseout', function(e)
		{
			setCroppedImageData();
		});


		$('[type="submit"]').on('click', function(e)
		{
			setCroppedImageData();
			return true;
		});

	}




	function setCroppedImageData()
	{
		var croppedData = $('.image-editor').cropit('export');

		console.log(croppedData);

		$('.cropit-cropped-input').val(croppedData);
	}



	function setErrorMessage(error)
	{
		var $formGroup = $('.image-editor').parents('.form-group');

		$formGroup.addClass('has-error');

		if($formGroup.find('.help-block').length === 0)
		{
			$('.image-input-holder').append('<div class="help-block">' + error.message + '</div>');
			return;
		}

		$formGroup.find('.help-block').text(error.message);
	}

	function clearErrorMessage()
	{
		var $formGroup = $('.image-editor').parents('.form-group');

		$formGroup.removeClass('has-error');
		$formGroup.find('.help-block').remove();
	}


}());